var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"scroll-y",attrs:{"id":"customers-list"}},[(!_vm.edit && !_vm.show)?_c('v-row',{attrs:{"justify":"center"}},[(_vm.ready)?_c('v-card',{staticClass:"transparent pb-12 px-12",attrs:{"flat":""}},[_c('v-card-title',[_c('CustomersFilters',{attrs:{"filter":_vm.filter,"refresh":_vm.refresh,"hardRefresh":_vm.hardRefresh,"partnerCustomers":_vm.partner},on:{"update:filter":function($event){_vm.filter=$event},"update:refresh":function($event){_vm.refresh=$event},"update:hardRefresh":function($event){_vm.hardRefresh=$event},"update:hard-refresh":function($event){_vm.hardRefresh=$event}}}),(_vm.showDeleteSelectedbutton)?_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.removeSelectedCustomers}},[_vm._v(" Delete selected customers ")]):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.customers,"options":_vm.options,"server-items-length":_vm.totalCustomers,"loading":_vm.loading,"search":_vm.search,"height":_vm.tableHeight,"show-expand":"","show-select":"","single-expand":"","expanded":_vm.expanded,"footer-props":{
          showFirstLastPage: true,
          itemsPerPageOptions: [10, 20, 50, 100, 500],
          firstIcon: 'mdi-skip-previous',
          lastIcon: 'mdi-skip-next',
          prevIcon: 'mdi-chevron-left',
          nextIcon: 'mdi-chevron-right'
        }},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event},"item-expanded":_vm.getCustomerDetails,"click:row":_vm.showItem},scopedSlots:_vm._u([{key:"footer.prepend",fn:function(){return [_c('v-text-field',{staticStyle:{"display":"inline-block","max-width":"360px"},attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('span',{staticClass:"mx-4"},[_vm._v("Total selected customers: "+_vm._s(_vm.totalCustomers))])]},proxy:true},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteCustomer(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.commercial",fn:function(ref){
        var item = ref.item;
return [(item.commercial)?_c('v-icon',{attrs:{"color":"#09b"}},[_vm._v(" mdi-alpha-c-box-outline ")]):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-alpha-r-box-outline ")])]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{staticClass:"pa-4",attrs:{"colspan":headers.length}},[_c('v-toolbar',{staticClass:"transparent",attrs:{"flat":""}},[_c('CustomerServices',{attrs:{"customerId":item.id}}),_c('v-spacer'),_c('v-icon',{attrs:{"color":"#09b"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])],1)],1)]}}],null,false,1088658696),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_vm._e()],1):_c('v-row',{attrs:{"justify":"center"}},[(_vm.customerReady && _vm.show)?_c('ShowCustomerDetails',{attrs:{"dialog":_vm.show,"customerId":_vm.selectedCustomerId,"customerUpdated":_vm.customerUpdated},on:{"update:dialog":function($event){_vm.show=$event},"update:customerUpdated":function($event){_vm.customerUpdated=$event},"update:customer-updated":function($event){_vm.customerUpdated=$event}}}):_vm._e(),(_vm.customerReady && _vm.edit)?_c('EditCustomer',{attrs:{"dialog":_vm.edit,"customerId":_vm.selectedCustomerId},on:{"update:dialog":function($event){_vm.edit=$event}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }